// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav {
  display: inline-grid;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: hidden;

  margin-bottom: 1vh;

  position: fixed;
  left: 1vw;
}

.nav-logo {
  width: 2.5vw;
  height: 2.5vw;
  margin-right: 0.5vw;
}

.diffrentPage  {
  color: #468189;
}

.currentPage {
  color: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/CSS/nav.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,wBAAmB;EAAnB,mBAAmB;EACnB,kBAAkB;;EAElB,kBAAkB;;EAElB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".nav {\n  display: inline-grid;\n  height: fit-content;\n  overflow-y: hidden;\n\n  margin-bottom: 1vh;\n\n  position: fixed;\n  left: 1vw;\n}\n\n.nav-logo {\n  width: 2.5vw;\n  height: 2.5vw;\n  margin-right: 0.5vw;\n}\n\n.diffrentPage  {\n  color: #468189;\n}\n\n.currentPage {\n  color: #FFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
