// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-one {
  grid-row: 1;
  margin-bottom: 2%;
}

.row-two {
  grid-row: 2;
  margin-bottom: 2%;
}

.row-three {
  grid-row: 3;
}

.column-one {
  grid-column: 1;
}

.column-two {
  grid-column: 2;
}

.column-three {
  grid-column: 3;
}

@media (min-width: 769px) {
  .skill {
    width: 25vw;
  }

  .skill-image {
    width: 50px;
    height: 50px;
    aspect-ratio: 1/1;
  }

  .skill-name {
    font-size: 2.5rem;
  }

  .skill-description {
    font-size: .825rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/CSS/skill.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".row-one {\n  grid-row: 1;\n  margin-bottom: 2%;\n}\n\n.row-two {\n  grid-row: 2;\n  margin-bottom: 2%;\n}\n\n.row-three {\n  grid-row: 3;\n}\n\n.column-one {\n  grid-column: 1;\n}\n\n.column-two {\n  grid-column: 2;\n}\n\n.column-three {\n  grid-column: 3;\n}\n\n@media (min-width: 769px) {\n  .skill {\n    width: 25vw;\n  }\n\n  .skill-image {\n    width: 50px;\n    height: 50px;\n    aspect-ratio: 1/1;\n  }\n\n  .skill-name {\n    font-size: 2.5rem;\n  }\n\n  .skill-description {\n    font-size: .825rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
