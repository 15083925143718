// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project {
  display: grid;
  gap: 2vw;

  padding-bottom: 3vh;

  height: -moz-fit-content;

  height: fit-content;
}

.project-img {
  grid-column: 1;
  aspect-ratio: 21 / 16;

  border-radius: 20px;

  overflow: hidden;
  box-shadow: 10px 15px 20px #234f56;
}

.project-text {
  grid-column: 2;
}

.project-techs {
  grid-column: 3;
}

.project-techs > img {
  max-width: 5vw;
  max-height: 5vw;

  aspect-ratio: 1 / 1;
}

.github-project-link {
  max-width: 4vw;
  max-height: 4vw;
  aspect-ratio: 1 / 1;

  display: flex;
  position: relative;

  left: 2vw;

  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/CSS/projects.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;;EAER,mBAAmB;;EAEnB,wBAAmB;;EAAnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,qBAAqB;;EAErB,mBAAmB;;EAEnB,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;;EAEf,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;;EAEnB,aAAa;EACb,kBAAkB;;EAElB,SAAS;;EAET,gBAAgB;AAClB","sourcesContent":[".project {\n  display: grid;\n  gap: 2vw;\n\n  padding-bottom: 3vh;\n\n  height: fit-content;\n}\n\n.project-img {\n  grid-column: 1;\n  aspect-ratio: 21 / 16;\n\n  border-radius: 20px;\n\n  overflow: hidden;\n  box-shadow: 10px 15px 20px #234f56;\n}\n\n.project-text {\n  grid-column: 2;\n}\n\n.project-techs {\n  grid-column: 3;\n}\n\n.project-techs > img {\n  max-width: 5vw;\n  max-height: 5vw;\n\n  aspect-ratio: 1 / 1;\n}\n\n.github-project-link {\n  max-width: 4vw;\n  max-height: 4vw;\n  aspect-ratio: 1 / 1;\n\n  display: flex;\n  position: relative;\n\n  left: 2vw;\n\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
